@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', sans-serif;
}

.active {
  background: rgb(8, 88, 187);
  font-weight: 500;
  color: #fff;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(203 213 225); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(100 116 139); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(71 85 105); 
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #334155 !important;
  background: #E2E8F0;
  /* display: flex;
  align-items: start; */
  font-weight: 600;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #334155 !important;
  background: transparent;
  /* display: flex !important;
  align-items: start !important; */
  font-weight: 600 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
  background: #E2E8F0;
}
.css-lfwcke-MuiTabs-flexContainer {
  width: fit-content;
}
.css-35zqo5-MuiTabs-root {
  overflow: unset;
}
.academia {
  background-image: url('./assets/images/academia.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.academia::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}
.professional {
  background-image: url('./assets/images/professional.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.professional::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}


.blog {
  background-image: url('./assets/images/blog.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.blog::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}
.contact {
  background-image: url('./assets/images/contact.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.contact::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}

.books {
  background-image: url('./assets/images/books.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.books::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}
.news {
  background-image: url('./assets/images/news.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 400px;
}
.news::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 1;
}

.myblockquote1::before {
  content: "";
  width: 3px;
  height: 30px;
  position: absolute;
  top: calc(100% - 30px);
  left: 0;
  background-color: black;
}
.myblockquote1::after {
  content: "";
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
}
.myblockquote2::before {
  content: "";
  width: 3px;
  height: 30px;
  position: absolute;
  bottom: calc(100% - 30px);
  right: 0;
  background-color: black;
}
.myblockquote2::after {
  content: "";
  width: 30px;
  height: 3px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
}


.portfolio #portfolio_item {
  border-top: 5px dashed teal;
  /* border-color: blue !important; */
  margin:0; padding: 30px;
  counter-increment: section;
  position: relative;
}



#portfolio_item:nth-child(even):before {
  content: "";
  right: 100%; 
  margin-right: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 45px;
  width: 45px;
  background-color: teal;
  text-align:center;
  color: white;
  font-size: 110%;
}

#portfolio_item:nth-child(odd):before {
  content: "";
  left: 100%; 
  margin-left: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 45px;
  width: 45px;
  background-color: teal;
  text-align:center;
  color: white;
  font-size: 110%;
}



#portfolio_item:nth-child(even) {
  border-left: 5px dashed teal;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 0;
  margin-right: 30px; 
  padding-right: 0;
}

#portfolio_item:nth-child(odd) {
  border-right: 5px dashed teal;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 0;
  margin-left: 23px;
  padding-left: 0;
}

#portfolio_item:first-child {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}

#portfolio_item:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
}