.shadow-text {
    position: absolute;
    font-size: 70px;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .05;
    font-weight: 900;
    letter-spacing: 5px;
}
.page-caption {
    font-size: 50px;
    font-weight: 900;
}
.portfolio_item_hover, .service_item_hover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0;
    transition:2s;
}
.portfolio_item_hover:hover, .service_item_hover:hover {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    margin: 0 auto;
    transition:.5s;
    opacity: .8;
    border-radius: 5px;
}

/* Carousel slider */
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: visible !important;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.swiper-button-next, .swiper-button-prev {
  position: absolute;
  background: rgb(79 70 229);
  color: white !important;
  top: 50%;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.swiper-button-next{
  right:.5%;
}
.swiper-button-prev{
  left:.5%;
}
.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  text-align: center !important;
  line-height: 20px !important;
  font-size: 12px !important;
  color: #000 !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.2) !important;
}
.swiper-wrapper {
  display: flex !important;
}
.swiper-pagination-bullet-active {
  color: #fff !important;
  background: rgb(99 102 241) !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 15px !important;
  font-weight: 800;
  text-transform: none!important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: -40px !important;
  left: 0;
  width: 100%;
}
  .carousel.carousel-slider .control-arrow {
    background: rgb(99 102 241) !important;
    height: 40px !important;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 50% !important;
  }
/* End Carousel slider */


/* Home Slider */
.homeslider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
.homeslider-container::after {
  position: absolute;
  content: "";
  background: #000;
  opacity: .5;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.homeSlide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.15);
  transition: opacity .6s ease;
}
.homeSlide.activeSlide {
  animation: grow 7s linear infinite;
  opacity: 1;
}
.slideText {
  opacity: 0;
}
.slideText.activeText {
  opacity: 1;
}
@keyframes grow {
  0%, 20%{
    transform: scale(1);
  }
  75%, 100%{
    transform: scale(1.15);
  }
} 

/* End of Home Slider */
.ag-row .ag-cell {
  display: flex;
  justify-content: start; /* align horizontal */
  align-items: center;
}
.Carousel__Item-sc-hyhecw-5.jjfwLM{
  display: flex;
  justify-content: center;
}

/* Add the hover class for background blur */
.hover {
  backdrop-filter: blur(8px);
}

/* Add the blur class for the image */
.blur {
  filter: blur(8px);
}

.box-shadow-style {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}